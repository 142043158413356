<template>
  <a-modal :visible="visible" :title='false' :footer="false" @ok="handleOk" @cancel="handleCancel">
    <div class="modal-title" style="padding-bottom:5px"><a-icon type="info-circle" :style="'margin-right:5px;color:'+color" />以下作品的术语未确认，请确认后再更改任务状态！</div>
    <div :style="backCss">
      <div v-for="item in productionList" :key="item.sequenceNo" class="term-item" @click="goToTerm(item)">
        {{ item.sequenceNo }}-{{ item.productionName }}
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      productionList:[],
      color:'#5389DF'
    }
  },
  computed: {
    backCss() {
      return {
        '--theme-color': this.color
      }
    }
  },
  created(){
    this.color=this.$store.state.app.color
  },
  methods: {
    goToTerm(item){
      window.open(`/production/manage/detail/${item.productionId}?&name=${item.productionName}&tab=6&onlySelectNoConfirm=true`)
    },
    open(productionList) {
      this.visible = true
      this.productionList=productionList
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.term-item{
  cursor: pointer;
  font-size:16px;
  line-height:32px;
  height:32px;
  padding-left:18px;
  text-decoration:underline;
  &:hover{
    color:var(--theme-color) !important;
  }
}
</style>
