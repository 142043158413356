<template>
  <a-modal :title="title" :width="700" :zIndex='2000' :visible="visible" @cancel="handleCancel" forceRender>
    <a-spin :spinning="loading">
      <div class="left" v-if="taskList.length" style="display: flex;flex-direction: column;">
        <a-icon v-if="taskPage" @click="prev" style="font-size:30px" type="left-circle" theme="filled" />
      </div>
      <div class="right" v-if="taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          v-if="taskPage < taskList.length - 1"
          @click="next"
          style="font-size:30px"
          type="right-circle"
          theme="filled"
        />
      </div>
      <a-radio-group v-model="radioValue">
        <a-radio v-if="formData.details && formData.details.length" value="1">
          系数参数
        </a-radio>
        <a-radio v-if="formData.noKpi" value="2">
          对其他阶段考核
        </a-radio>
      </a-radio-group>
      <a-form-model v-if="radioValue == '1'" style="padding-top:40px;overflow-y: overlay;max-height: 300px;" ref="form">
        <a-form-model-item v-for="item in formData.details" class="edit-form" :key="item.id" v-bind="layout">
          <template v-if="item.cardList[0]">
            <div :class="item.cardList[0].dataList[0].valueRequired == 'YES' ? 'required' : 'no-required'" slot="label">
              {{ item.title }}
              <span style="margin-left:-2px">:</span>
              <div v-if="item.cardList[0].dataList && item.cardList.length > 1">
                <div :ref="item.cardList[0].id + 'TEXTAREA'" class="description-div">
                  {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                  }}{{ !item.canSync ? '（已更新数据）' : '' }}
                </div>
              </div>
              <div v-if="item.cardList[0].dataList && item.cardList.length == 1">
                <div
                  v-if="item.cardList[0].type != 'TEXTAREA'"
                  :class="
                    item.cardList[0].dataList[0].formValues.length > 1 && item.cardList[0].type != 'TAG'
                      ? 'description-div'
                      : 'description-div-one'
                  "
                >
                  {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                  }}{{ !item.canSync ? '（已更新数据）' : '' }}
                </div>
                <div v-else :ref="item.cardList[0].id + 'TEXTAREA'" :class="getClassName(item.cardList[0], true)">
                  {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                  }}{{ !item.canSync ? '（已更新数据）' : '' }}
                </div>
              </div>
            </div>
          </template>

          <template v-for="wrapper in item.cardList">
            <div
              v-if="wrapper.dataList"
              :key="wrapper.id"
              :style="
                wrapper.type == 'TEXTAREA'
                  ? 'margin-bottom:2px;display:flex;flex-wrap:wrap'
                  : 'display:flex;flex-wrap:wrap'
              "
            >
              <a-input
                style="margin-bottom: 6px;margin-top:4px;"
                v-if="wrapper.type == 'INPUT'"
                :ref="'input' + wrapper.id"
                :placeholder="wrapper.dataList[0].description"
                v-model="wrapper.dataList[0].formValues[0].value"
                @blur="
                  e => {
                    inputBlur(e, wrapper)
                  }
                "
                :disabled="isSucDis(item)"
                @change="
                  e => {
                    inputChange(e, wrapper)
                  }
                "
              />
              <a-input-number
                style="margin-bottom: 6px;margin-top:4px;"
                :min="0"
                v-if="wrapper.type == 'INPUT_NUMBER'"
                :ref="'input' + wrapper.id"
                :placeholder="wrapper.dataList[0].description"
                v-model="wrapper.dataList[0].formValues[0].value"
                @blur="
                  e => {
                    inputNumberBlur(e, wrapper, item)
                  }
                "
                :disabled="isSucDis(item)"
                @change="
                  e => {
                    inputNumberChange(e, wrapper, item)
                  }
                "
              />
              <div
                v-if="wrapper.type == 'DYNAMIC_FORM'"
                style="display:flex;justify-content: space-between;align-items: center;width: 580px;margin-top:-6px;"
              >
                <template v-for="data in wrapper.dataList">
                  <a-form-model-item :key="data.id" :label="data.value" class="form-select" v-bind="inlineLayout">
                    <div v-if="data.type != 'INPUT'" style="padding-top:6px;">
                      <div v-for="(formValue, index) in data.formValues" :key="index">
                        <a-select
                          :ref="'select' + data.id + index"
                          allowClear
                          :placeholder="wrapper.dataList[0].description"
                          :style="
                            wrapper.dataList.length > 1 && !data.value
                              ? 'width:243px'
                              : wrapper.dataList.length > 1 && data.value
                              ? 'width:173px'
                              : data.value
                              ? 'width:430px;'
                              : 'width:18vw'
                          "
                          v-model="formValue.value"
                          @change="
                            e => {
                              formSelectChange(e, data, wrapper.dataList, index, item)
                            }
                          "
                          :disabled="isSucDis(item)"
                        >
                          <a-select-option v-for="option in data.options" :key="option.id" :value="option.value">{{
                            option.label
                          }}</a-select-option>
                        </a-select>
                        <div
                          :ref="data.id + '' + index"
                          class="ant-form-explain"
                          style="width: 100%;color:#f5222d;display:none"
                        >
                          请选择{{ item.title }}
                        </div>
                      </div>
                    </div>
                    <div v-else style="margin-top:6px;">
                      <div v-for="(formValue, index) in data.formValues" :key="index">
                        <a-input
                          :disabled="isSucDis(item)"
                          :ref="'input' + data.id + index"
                          :placeholder="wrapper.dataList[0].type == 'INPUT' ? wrapper.dataList[0].description : ''"
                          :style="
                            wrapper.dataList.length > 1 && !data.value
                              ? 'width:243px'
                              : wrapper.dataList.length > 1 && data.value
                              ? 'width:173px'
                              : data.value
                              ? 'width:430px;'
                              : 'width:18vw'
                          "
                          @blur="
                            e => {
                              formInputBlur(e, data, wrapper.dataList, index)
                            }
                          "
                          @change="
                            e => {
                              formInputChange(e, data, wrapper.dataList, index)
                            }
                          "
                          v-model="formValue.value"
                        />
                        <div
                          :ref="data.id + '' + index"
                          class="ant-form-explain"
                          style="width: 100%;color:#f5222d;display:none"
                        >
                          请输入{{ item.title }}
                        </div>
                      </div>
                    </div>
                  </a-form-model-item>
                </template>
                <div
                  v-if="wrapper.canAdd"
                  :class="wrapper.dataList.length > 1 ? '' : 'only'"
                  style="min-width:45px;margin-left:10px;display: flex;justify-content: space-between;height: 100%;padding-top:16px;"
                >
                  <a-button
                    :disabled="isSucDis(item)"
                    class="item-btn"
                    shape="circle"
                    size="small"
                    icon="minus"
                    @click="delItem(wrapper)"
                  />
                  <a-button
                    :disabled="isSucDis(item)"
                    style="margin-left:8px;"
                    class="item-btn"
                    shape="circle"
                    size="small"
                    icon="plus"
                    @click="addItem(wrapper)"
                  />
                </div>
                <div v-if="!wrapper.canAdd" style="width:55px;margin-left:10px;"></div>
              </div>
              <div class="form-select" style="width:18vw" v-if="wrapper.type == 'SELECT'">
                <a-select
                  :disabled="isSucDis(item)"
                  :ref="'select' + wrapper.id"
                  allowClear
                  :showArrow="true"
                  :mode="wrapper.dataList[0].selectCount == 1 ? '' : 'multiple'"
                  v-model="wrapper.dataList[0].selectValues"
                  :placeholder="wrapper.dataList[0].description"
                  @change="
                    e => {
                      selectChange(e, wrapper)
                    }
                  "
                >
                  <a-select-option v-for="option in wrapper.dataList[0].options" :key="option.id" :value="option.value">
                    <div slot="option">
                      {{ option.label }}
                    </div>
                  </a-select-option>
                </a-select>
              </div>
              <div class="form-select" style="width:18vw" v-if="wrapper.type == 'TAG' && !isSucDis(item)" @click.stop>
                <div style="height:40px;display: flex;align-items: center;">
                  <div @click.self="isTagShow(wrapper)" :class="wrapper.isTagShow ? 'tag-select-show' : 'tag-select'">
                    <template v-if="wrapper.dataList[0].formValues && wrapper.dataList[0].formValues[0].value">
                      <a-tag
                        closable
                        @close="tagClose(formValue.value, wrapper.dataList[0].formValues, wrapper.dataList[0].options)"
                        :key="formValue.value"
                        v-for="formValue in wrapper.dataList[0].formValues"
                        >{{ formValue.value }}</a-tag
                      >
                    </template>
                    <div class="tag-select-open " :style="wrapper.isTagShow ? '' : 'height:0px;border:none'">
                      <template v-if="wrapper.dataList[0].formValues[0].value">
                        <a-checkable-tag
                          :ref="option.label"
                          v-for="option in wrapper.dataList[0].options"
                          :key="option.label"
                          v-model="option.checked"
                          :style="
                            wrapper.dataList[0].formValues.length >= wrapper.dataList[0].selectCount && !option.checked
                              ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                              : ''
                          "
                          @change="tagChange(option.checked, wrapper, option)"
                          >{{ option.label }}</a-checkable-tag
                        >
                      </template>
                      <template v-else>
                        <a-checkable-tag
                          :ref="option.label"
                          v-for="option in wrapper.dataList[0].options"
                          :key="option.label"
                          v-model="option.checked"
                          @change="tagChange(option.checked, wrapper, option)"
                          >{{ option.label }}</a-checkable-tag
                        >
                      </template>
                    </div>
                    <span class="ant-select-arrow">
                      <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="wrapper.isTagShow ? 'up' : 'down'"></a-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div v-else-if="wrapper.type == 'TAG' && isSucDis(item)" class="dis-tag">
                <span class="ant-select-arrow">
                  <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="'down'"></a-icon>
                </span>
              </div>
              <a-textarea
                :disabled="isSucDis(item)"
                v-if="wrapper.type == 'TEXTAREA'"
                :ref="'input' + wrapper.id"
                :auto-size="{ minRows: 1, maxRows: 5 }"
                style="width:18vw;margin-top:4px;"
                :placeholder="wrapper.dataList[0].description"
                v-model.lazy="wrapper.dataList[0].formValues[0].value"
                @blur="
                  e => {
                    inputBlur(e, wrapper)
                  }
                "
                @change="
                  e => {
                    inputChange(e, wrapper, item)
                  }
                "
              />
              <div class="zsxz" v-if="wrapper.type == 'INPUT' || wrapper.type == 'TEXTAREA'">
                {{ getRange(wrapper.dataList[0]) }}
              </div>
              <div
                v-if="wrapper.type == 'INPUT' || wrapper.type == 'TEXTAREA' || wrapper.type == 'INPUT_NUMBER'"
                :ref="wrapper.id"
                class="ant-form-explain"
                style="color:#f5222d;display:none;margin-left:5px;align-items: center;"
              >
                请输入整数数字
              </div>
              <div
                v-if="wrapper.type == 'SELECT'"
                :ref="wrapper.id"
                class="ant-form-explain"
                style="width: 100%;color:#f5222d;display:none"
              >
                请选择{{ item.title }}
              </div>
            </div>
          </template>
        </a-form-model-item>
      </a-form-model>
      <div v-if="formData.noKpi && radioValue == '2'" style="width:80%;padding-top:20px;">
        <div style="padding-bottom:40px;" v-for="item in kpiList" :key="item.id">
          <div class="coef_item" style="display:flex;justify-content:space-between">
            <div style="font-weight:bold;font-size:14px">当前阶段对【{{ item.stage.stageName }}】阶段考核结果</div>
            <div></div>
          </div>
          <template
            v-for="kpi in item.kpiValues.filter(kpi => {
              return (
                (formData.stageName == '监制' &&
                  (kpi.errorType == 'SUPER_HARD' ||
                    kpi.errorType == 'HARD' ||
                    kpi.errorType == 'SUPER_SOFT' ||
                    kpi.errorType == 'SOFT')) ||
                (formData.stageName == '监制确认' &&
                  (kpi.errorType == 'V1_HARD' || kpi.errorType == 'V2_HARD' || kpi.errorType == 'MORE_THAN_V2_HARD')) ||
                (formData.stageName != '监制确认' && formData.stageName != '监制')
              )
            })"
          >
            <div
              v-if="kpi.kpiType == 'KPI_ERRORS'"
              :key="kpi.id"
              class="coef_item"
              style="display:flex;padding-top:13px;justify-content:space-between"
            >
              <div style="display:flex">
                <div style="font-size:14px">
                  {{
                    kpi.errorType == 'SOFT'
                      ? '软伤数'
                      : kpi.errorType == 'HARD'
                      ? '硬伤数'
                      : kpi.errorType == 'ERROR'
                      ? '错翻数'
                      : kpi.errorType == 'SUPER_HARD'
                      ? '超级硬伤数'
                      : kpi.errorType == 'V1_HARD'
                      ? '1改硬伤数'
                      : kpi.errorType == 'V2_HARD'
                      ? '2改硬伤数'
                      : kpi.errorType == 'MORE_THAN_V2_HARD'
                      ? '2改以上硬伤数'
                      : kpi.errorType == 'SUPER_SOFT'
                      ? '超级软伤'
                      : ''
                  }}
                </div>
              </div>
              <div style="font-size:14px">
                <div v-if="kpi.isEdit">
                  <a-input-number
                    :min="0"
                    v-model="kpi.value"
                    style="width:45px;padding-left:4px;padding-right:4px;font-size:14px;padding-top:0;padding-bottom:0"
                    @change="
                      e => {
                        kpiInputChange(e, kpi)
                      }
                    "
                  />个
                </div>
                <div v-else>{{ kpi.value == null ? '/   ' : kpi.value }}个</div>
              </div>
            </div>
          </template>
          <template v-for="kpi in item.kpiValues">
            <div
              v-if="kpi.kpiType != 'KPI_ERRORS' && formData.stageName != '监制确认'"
              :key="kpi.id"
              class="coef_item"
              style="display:flex;padding-top:13px;justify-content:space-between"
            >
              <div style="display:flex">
                <div style="font-size:14px">
                  修改量
                </div>
              </div>
              <div style="font-size:14px">
                <div v-if="kpi.isEdit">
                  <a-input-number
                    :min="0"
                    v-model="kpi.value"
                    style="width:45px;padding-left:4px;padding-right:4px;font-size:14px;padding-top:0;padding-bottom:0"
                    @change="
                      e => {
                        kpiInputChange(e, kpi)
                      }
                    "
                  />个
                </div>
                <div v-else>{{ kpi.value == null ? '/   ' : kpi.value }}个</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </a-spin>
    <template slot="footer">
      <div>
        <a-button key="back" @click="handleCancel">
          关闭
        </a-button>
        <a-button :loading="btnLoading" key="submit" @click="handleOk">
          <div v-if="!btnLoading" class="statusPointer" :style="{ background: 'green' }"></div>
          <span>已完成</span>
        </a-button>
      </div>
    </template>
    <term-list-modal ref="termListModal" />
  </a-modal>
</template>

<script>
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import termListModal from '@/components/termListModal'
import { postAction, getAction } from '@/api/manage'
import { upstatusDownloadFile } from '@/utils/util.js'
export default {
  components: {
    termListModal
  },
  props: {
    /*     taskList: {
      type: Array,
      default: () => []
    } */
  },
  data() {
    return {
      visible: false,
      radioValue: '1',
      taskList: [],
      title: '填写参数',
      loading: false,
      validate: true,
      taskPage: 0,
      formData: {
        details: []
      },
      kpiList: [],
      labelWidth: '80',
      layout: {
        labelCol: { style: 'width: 30% !important;', span: 6 },
        wrapperCol: { span: 14 }
      },
      inlineLayout: {
        labelCol: { style: 'width: 70px;', span: 4 },
        wrapperCol: { span: 20 }
      },
      userInfo: {},
      isAdmin: false,
      hasFeedback: false,
      oldData: [],
      btnLoading: false
    }
  },
  created() {
    const userInfo = Vue.ls.get(USER_INFO)
    this.userInfo = Object.assign({}, userInfo)
    this.isAdmin = this.userInfo.roles.some(item => {
      return item.roleCode == 'admin' || item.roleCode == 'boss'
    })
  },
  watch: {
    async radioValue() {
      if (this.radioValue == '2') {
        this.loading = true
        const rest = await getAction('/factorView/othersKpiView', {
          kpiToTaskId: this.formData.taskId,
          globalStageId: this.formData.stageId,
          chapterId: this.formData.chapterId,
          productId: this.formData.productionId
        })
        try {
          if (rest.code == 200) {
            rest.data.forEach(item => {
              item.kpiValues.forEach(kpi => {
                kpi.isEdit = true
              })
            })
            this.kpiList = rest.data
            this.loading = false
          }
        } catch (err) {
          this.loading = false
        }
      }
    }
  },
  methods: {
    async prev() {
      this.loading = true
      this.radioValue = '1'
      if (this.formData.details) {
        this.validate = true
        this.formData.details?.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(wrapper => {
              if (wrapper.dataList.length == 1) {
                if (wrapper.type == 'INPUT') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                    this.$refs[ref][0].$el.classList.add('antd-input-vis')
                    this.validate = false
                  } else if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + ''
                  ) {
                    if (wrapper.dataList[0].limitRange) {
                      switch (wrapper.dataList[0].limitRange.type) {
                        case 'GT':
                          if (wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'LT':
                          if (wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'BETWEEN':
                          if (
                            wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.start ||
                            wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.end
                          ) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                      }
                    }
                  }
                } else if (wrapper.type == 'INPUT_NUMBER') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'SELECT') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'select' + wrapper.id
                    this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'DYNAMIC_FORM') {
                  wrapper.dataList.forEach(data => {
                    data.formValues.forEach((formValue, index) => {
                      if (wrapper.dataList[0].valueRequired == 'YES' && !formValue.value) {
                        if (data.type == 'INPUT') {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'input' + data.id + index
                          this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                          this.$refs[ref][0].$el.classList.add('antd-input-vis')
                          this.validate = false
                        } else {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'select' + data.id + index
                          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                          this.validate = false
                        }
                      }
                    })
                  })
                }
              } else {
                wrapper.dataList.forEach(data => {
                  data.formValues.forEach((formValue, index) => {
                    if (wrapper.dataList[0].valueRequired == 'YES' && !(formValue.value + '')) {
                      if (data.type == 'INPUT') {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'input' + data.id + index
                        this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                        this.validate = false
                      } else {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'select' + data.id + index
                        this.$refs[ref][0].$el.style.border = '1px solid #f5222d'
                        this.$refs[ref][0].$el.style.borderRadius = '4px'
                        this.validate = false
                      }
                    }
                  })
                })
              }
            })
          }
        })
        if (this.validate) {
          try {
            this.formData.details.forEach((item, index) => {
              if (item.cardList) {
                item.cardList.forEach(value => {
                  value.dataList.forEach(i => {
                    delete i.selectValues
                  })
                })
              }
              item.cardList.forEach((wrapper, idx) => {
                if (
                  wrapper.dataList[0].formValues[0].value !=
                  this.oldData[index].cardList[idx].dataList[0].formValues[0].value
                ) {
                  item.canSync = false
                }
              })
            })
            const res = await postAction('/productDetail/batch', {
              list: this.formData.details,
              editType: 'TASK',
              domain: 'TASK_DETAIL',
              type: 'CHAPTER',
              relationId: this.formData.chapterId
            })
            if (!res.success) {
              this.$message.error(res.msg || res.message)
              this.loading = false
              return
            }
          } catch (err) {
            this.$message.error(err)
            this.loading = false
            return
          }
        } else {
          this.$message.error('请填写系数参数必填项')
          this.loading = false
          return
        }
      }
      await postAction('/factorView/kpiToOthers', this.kpiList)
      try {
        this.kpiList = []
        this.loading = false
      } catch (err) {
        this.$message.error(err)
        this.loading = false
        return
      }
      this.taskList[this.taskPage] = this.formData
      this.taskPage--
      this.taskList?.forEach(task => {
        task.details?.forEach(item => {
          if (item.cardList) {
            item.cardList?.forEach(value => {
              value.isTagShow = false
              if (value.dataList) {
                value.dataList?.forEach(i => {
                  i.selectValues = []
                  if (!i.formValues) {
                    i.formValues = []
                    i.formValues.push({ value: null })
                    i.type == 'TAG' && (i.formValues[0].value = undefined)
                  } else {
                    i.formValues?.forEach(select => {
                      if (i.options && select.value) {
                        i.options.forEach(option => {
                          if (option.value == select.value) {
                            option.checked = true
                          }
                        })
                      }
                      if (select.value) {
                        i.selectValues.push(select.value)
                      }
                    })
                  }
                })
              } else {
                value.dataList = [
                  {
                    formValues: [{ value: null }]
                  }
                ]
              }
            })
          } else {
            item.cardList = []
          }
        })
      })
      this.formData = this.taskList[this.taskPage]
      this.title =
        '填写参数' +
        '(' +
        this.formData.productName +
        '-' +
        this.formData.chapterOrder +
        '-' +
        this.formData.taskName +
        ')'
      if (!this.formData.details || !this.formData.details.length) {
        this.radioValue = '2'
      } else {
        this.radioValue = '1'
        this.oldData = JSON.parse(JSON.stringify(this.formData.details))
      }
      this.$forceUpdate()
    },
    async next() {
      this.loading = true
      this.radioValue = '1'
      if (this.formData.details) {
        this.validate = true
        this.formData.details?.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(wrapper => {
              if (wrapper.dataList.length == 1) {
                if (wrapper.type == 'INPUT') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                    this.$refs[ref][0].$el.classList.add('antd-input-vis')
                    this.validate = false
                  } else if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + ''
                  ) {
                    if (wrapper.dataList[0].limitRange) {
                      switch (wrapper.dataList[0].limitRange.type) {
                        case 'GT':
                          if (wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'LT':
                          if (wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'BETWEEN':
                          if (
                            wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.start ||
                            wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.end
                          ) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                      }
                    }
                  }
                } else if (wrapper.type == 'INPUT_NUMBER') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'SELECT') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'select' + wrapper.id
                    this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'DYNAMIC_FORM') {
                  wrapper.dataList.forEach(data => {
                    data.formValues.forEach((formValue, index) => {
                      if (wrapper.dataList[0].valueRequired == 'YES' && !formValue.value) {
                        if (data.type == 'INPUT') {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'input' + data.id + index
                          this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                          this.$refs[ref][0].$el.classList.add('antd-input-vis')
                          this.validate = false
                        } else {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'select' + data.id + index
                          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                          this.validate = false
                        }
                      }
                    })
                  })
                }
              } else {
                wrapper.dataList.forEach(data => {
                  data.formValues.forEach((formValue, index) => {
                    if (wrapper.dataList[0].valueRequired == 'YES' && !(formValue.value + '')) {
                      if (data.type == 'INPUT') {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'input' + data.id + index
                        this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                        this.validate = false
                      } else {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'select' + data.id + index
                        this.$refs[ref][0].$el.style.border = '1px solid #f5222d'
                        this.$refs[ref][0].$el.style.borderRadius = '4px'
                        this.validate = false
                      }
                    }
                  })
                })
              }
            })
          }
        })
        if (this.validate) {
          try {
            this.formData.details?.forEach((item, index) => {
              if (item.cardList) {
                item.cardList.forEach(value => {
                  value.dataList.forEach(i => {
                    delete i.selectValues
                  })
                })
              }
              item.cardList.forEach((wrapper, idx) => {
                if (
                  wrapper.dataList[0].formValues[0].value !=
                  this.oldData[index].cardList[idx].dataList[0].formValues[0].value
                ) {
                  item.canSync = false
                }
              })
            })
            const res = await postAction('/productDetail/batch', {
              list: this.formData.details,
              editType: 'TASK',
              domain: 'TASK_DETAIL',
              type: 'CHAPTER',
              relationId: this.formData.chapterId
            })
            if (!res.success) {
              this.$message.error(res.msg || res.message)
              this.loading = false
            }
          } catch (err) {
            this.$message.error(err)
            this.loading = false
          }
        } else {
          this.$message.error('请填写系数参数必填项')
          this.loading = false
        }
      }
      await postAction('/factorView/kpiToOthers', this.kpiList)
      try {
        this.kpiList = []
        this.loading = false
      } catch (err) {
        this.$message.error(err)
        this.loading = false
        return
      }
      this.taskList[this.taskPage] = this.formData
      this.taskPage++
      this.taskList?.forEach(task => {
        task.details?.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(value => {
              value.isTagShow = false
              if (value.dataList) {
                value.dataList.forEach(i => {
                  i.selectValues = []
                  if (!i.formValues) {
                    i.formValues = []
                    i.formValues.push({ value: null })
                    i.type == 'TAG' && (i.formValues[0].value = undefined)
                  } else {
                    i.formValues.forEach(select => {
                      if (i.options) {
                        i.options.forEach(option => {
                          if (option.value == select.value) {
                            option.checked = true
                          }
                        })
                      }
                      if (select.value) {
                        i.selectValues.push(select.value)
                      }
                    })
                  }
                })
              } else {
                value.dataList = [
                  {
                    formValues: [{ value: null }]
                  }
                ]
              }
            })
          } else {
            item.cardList = []
          }
        })
      })
      this.formData = this.taskList[this.taskPage]
      this.title =
        '填写参数' +
        '(' +
        this.formData.productName +
        '-' +
        this.formData.chapterOrder +
        '-' +
        this.formData.taskName +
        ')'
      if (!this.formData.details || !this.formData.details.length) {
        this.radioValue = '2'
      } else {
        this.radioValue = '1'
        this.oldData = JSON.parse(JSON.stringify(this.formData.details))
      }
      this.$forceUpdate()
    },
    handleCancel() {
      this.visible = false
      this.inlitData()
    },
    inlitData() {
      this.formData = {
        details: []
      }
      this.kpiList = []
      this.radioValue = '1'
      this.taskPage = 0
      this.validate = true
      this.$emit('closeTasksModel')
    },
    async handleOk() {
      this.btnLoading = true
      if (this.formData.details) {
        this.validate = true
        this.formData.details.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(wrapper => {
              if (wrapper.dataList.length == 1) {
                if (wrapper.type == 'INPUT') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                    this.$refs[ref][0].$el.classList.add('antd-input-vis')
                    this.validate = false
                  } else if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + ''
                  ) {
                    if (wrapper.dataList[0].limitRange) {
                      switch (wrapper.dataList[0].limitRange.type) {
                        case 'GT':
                          if (wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'LT':
                          if (wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'BETWEEN':
                          if (
                            wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.start ||
                            wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.end
                          ) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                      }
                    }
                  }
                } else if (wrapper.type == 'INPUT_NUMBER') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'SELECT') {
                  if (
                    wrapper.dataList[0].valueRequired == 'YES' &&
                    wrapper.dataList[0].formValues[0].value + '' == 'null'
                  ) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'select' + wrapper.id
                    this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'DYNAMIC_FORM') {
                  wrapper.dataList.forEach(data => {
                    data.formValues.forEach((formValue, index) => {
                      if (wrapper.dataList[0].valueRequired == 'YES' && !formValue.value) {
                        if (data.type == 'INPUT') {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'input' + data.id + index
                          this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                          this.$refs[ref][0].$el.classList.add('antd-input-vis')
                          this.validate = false
                        } else {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'select' + data.id + index
                          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                          this.validate = false
                        }
                      }
                    })
                  })
                }
              } else {
                wrapper.dataList.forEach(data => {
                  data.formValues.forEach((formValue, index) => {
                    if (wrapper.dataList[0].valueRequired == 'YES' && !(formValue.value + '')) {
                      if (data.type == 'INPUT') {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'input' + data.id + index
                        this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                        this.validate = false
                      } else {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'select' + data.id + index
                        this.$refs[ref][0].$el.style.border = '1px solid #f5222d'
                        this.$refs[ref][0].$el.style.borderRadius = '4px'
                        this.validate = false
                      }
                    }
                  })
                })
              }
            })
          }
        })
        if (this.validate) {
          try {
            this.formData.details.forEach((item, index) => {
              if (item.cardList) {
                item.cardList.forEach(value => {
                  value.dataList.forEach(i => {
                    delete i.selectValues
                  })
                })
              }
              item.cardList.forEach((wrapper, idx) => {
                if (
                  wrapper.dataList[0].formValues[0].value !=
                  this.oldData[index].cardList[idx].dataList[0].formValues[0].value
                ) {
                  item.canSync = false
                }
              })
            })
            const res = await postAction('/productDetail/batch', {
              list: this.formData.details,
              editType: 'TASK',
              domain: 'TASK_DETAIL',
              type: 'CHAPTER',
              relationId: this.formData.chapterId
            })
            if (!res.success) {
              this.$message.error(res.msg || res.message)
              this.loading = false
              this.btnLoading = false
              return
            }
          } catch (err) {
            this.$message.error(err)
            this.loading = false
            this.btnLoading = false
            return
          }
        } else {
          this.$message.error('请填写系数参数必填项')
          this.loading = false
          this.btnLoading = false
          return
        }
      }

      if (this.kpiList.length) {
        await postAction('/factorView/kpiToOthers', this.kpiList)
        try {
        } catch (err) {
          this.btnLoading = false
          this.$message.error(err)
        }
      }
      let params = { operator: 'FINISHED', ignoreKpi: true, sourceTaskIds: [] }
      params.sourceTaskIds = this.taskList.map(item => item.taskId)
      if (this.taskList.some(item => item.stageName == '甲方反馈')) {
        params.hasFeedback = this.hasFeedback
      }
      const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
      if (response.code == 200) {
        if (response.data.productionList && response.data.productionList.length) {
          this.$refs.termListModal.open(response.data.productionList)
        } else {
          const res = await postAction('/tasks/batch/updateStatus', params)
          if (res.code == 200) {
            upstatusDownloadFile(params.sourceTaskIds)
            this.$message.success('已完成')
            this.$emit('closeTasksModel', res.data)
            this.loading = false
            this.visible = false
            this.btnLoading = false
            this.inlitData()
          } else {
            this.loading = false
            this.btnLoading = false
            this.$message.error(res.msg || res.message)
          }
        }
      }
    },
    async open(taskList, hasFeedback) {
      this.taskList = taskList
      this.hasFeedback = hasFeedback
      this.taskList?.forEach(task => {
        task.details?.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(value => {
              value.isTagShow = false
              if (value.dataList) {
                value.dataList.forEach(i => {
                  i.selectValues = []
                  if (!i.formValues) {
                    i.formValues = []
                    i.formValues.push({ value: null })
                    i.type == 'TAG' && (i.formValues[0].value = undefined)
                  } else {
                    i.formValues.forEach(select => {
                      if (i.options) {
                        i.options.forEach(option => {
                          if (option.value == select.value) {
                            option.checked = true
                          }
                        })
                      }
                      if (select.value) {
                        i.selectValues.push(select.value)
                      }
                    })
                  }
                })
              } else {
                value.dataList = [
                  {
                    formValues: [{ value: null }]
                  }
                ]
              }
            })
          } else {
            item.cardList = []
          }
        })
      })
      this.formData = this.taskList[this.taskPage]
      if (!this.formData.details || !this.formData.details.length) {
        this.radioValue = '2'
      } else {
        this.oldData = JSON.parse(JSON.stringify(this.formData.details))
        if (this.formData.noKpi) {
          this.loading = true
          const rest = await getAction('/factorView/othersKpiView', {
            kpiToTaskId: this.formData.taskId,
            globalStageId: this.formData.stageId,
            chapterId: this.formData.chapterId,
            productId: this.formData.productionId
          })
          try {
            if (rest.code == 200) {
              rest.data.forEach(item => {
                item.kpiValues.forEach(kpi => {
                  kpi.isEdit = true
                })
              })
              this.kpiList = rest.data
              this.loading = false
            }
          } catch (err) {
            this.loading = false
          }
        }
      }
      this.title =
        '填写参数' +
        '(' +
        this.formData.productName +
        '-' +
        this.formData.chapterOrder +
        '-' +
        this.formData.taskName +
        ')'
      this.visible = true
    },
    isTagShow(wrapper) {
      wrapper.isTagShow = !wrapper.isTagShow
    },
    tagChange(e, wrapper, option) {
      if (e) {
        if (wrapper.dataList[0].formValues && wrapper.dataList[0].formValues[0].value != '') {
          wrapper.dataList[0].formValues.push({ value: option.label })
        } else if (wrapper.dataList[0].formValues && wrapper.dataList[0].formValues[0].value == '') {
          wrapper.dataList[0].formValues.pop()
          wrapper.dataList[0].formValues.push({ value: option.label })
        }
      } else {
        let deleteIndex = wrapper.dataList[0].formValues
          .map(item => {
            return item.value
          })
          .indexOf(option.label)
        if (wrapper.dataList[0].formValues.length == 1) {
          wrapper.dataList[0].formValues = [{ value: '' }]
        } else {
          wrapper.dataList[0].formValues.splice(deleteIndex, 1)
        }
      }
    },
    tagClose(formValue, formValues, options) {
      this.$nextTick(() => {
        options[
          options
            .map(item => {
              return item.value
            })
            .indexOf(formValue)
        ].checked = false
        let deleteIndex = formValues
          .map(item => {
            return item.value
          })
          .indexOf(formValue)
        if (formValues.length == 1) {
          formValues.pop()
          formValues.push({ value: '' })
        } else {
          formValues.splice(deleteIndex, 1)
        }
      })
    },
    getSelectCount(data) {
      if (data.type == 'SELECT' || data.type == 'TAG') {
        if (data.dataList[0].selectCount == 1) {
          return '单选/'
        } else if (data.dataList[0].selectCount > 1) {
          return '最多' + data.dataList[0].selectCount + '个' + '/'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    selectChange(e, wrapper) {
      if (typeof e == 'string') e = [e]
      if (e) {
        if (e.length <= wrapper.dataList[0].selectCount) {
          wrapper.dataList[0].selectValues = e
          wrapper.dataList[0].formValues = []
          e.forEach(item => {
            wrapper.dataList[0].formValues.push({ value: item })
          })
        } else {
          e.pop()
        }
        if (e.length > 0 && wrapper.dataList[0].valueRequired == 'YES') {
          this.$refs[wrapper.id][0].style.display = 'none'
          const ref = 'select' + wrapper.id
          this.$refs[ref][0].$el.firstChild.classList.remove('ant-select-selection-vis')
        } else if (e.length == 0 && wrapper.dataList[0].valueRequired == 'YES') {
          /* this.$refs[wrapper.id][0].style.display = 'block' */
          const ref = 'select' + wrapper.id
          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
        }
      } else {
        wrapper.dataList[0].formValues = []
        e = null
      }
      /* item.canSync = false */
    },
    formSelectChange(e, data, dataList, index) {
      if (e) {
        if (e && dataList[0].valueRequired == 'YES') {
          this.$refs[data.id + '' + index][0].style.display = 'none'
          const ref = 'select' + data.id + index
          this.$refs[ref][0].$el.firstChild.classList.remove('ant-select-selection-vis')
        } else if (e.length == 0 && wrapper.dataList[0].valueRequired == 'YES') {
          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
          const ref = 'select' + data.id + index
          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
        }
      } else {
        e = null
      }
    },
    delItem(wrapper) {
      wrapper.dataList.forEach(item => {
        if (item.formValues.length > 1) item.formValues.pop()
      })
    },
    addItem(wrapper) {
      wrapper.dataList.forEach(item => {
        item.formValues.push({ value: undefined })
      })
    },
    formInputBlur(e, data, dataList, index) {
      if (!e.target.value && dataList[0].valueRequired == 'YES') {
        this.$nextTick(() => {
          e.target.style.borderColor = '#f5222d'
        })
      }
    },
    formInputChange(e, data, dataList, index) {
      if (e.target.value && dataList[0].valueRequired == 'YES') {
        this.$refs[data.id + '' + index][0].style.display = 'none'
        e.target.style.borderColor = '#d9d9d9'
      }
    },
    inputNumberChange(e, wrapper, item) {
      const ref = 'input' + wrapper.id
      if (e && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
      } else if (!e && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
      }
      if (e && !/(^[0-9]\d*$)/.test(e)) {
        this.$refs[wrapper.id][0].style.display = 'flex'
        this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
      } else if (e && /(^[0-9]\d*$)/.test(e)) {
        this.$refs[wrapper.id][0].style.display = 'none'
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
      }
      /* item.canSync = false */
    },
    inputBlur(e, wrapper) {
      if (!e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$nextTick(() => {
          e.target.style.borderColor = '#f5222d'
          e.target.classList.add('antd-input-vis')
        })
      } else if (e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[wrapper.id][0].style.display = 'none'
        e.target.style.borderColor = '#d9d9d9'
        e.target.classList.remove('antd-input-vis')
      }
      if (wrapper.dataList[0].limitRange) {
        switch (wrapper.dataList[0].limitRange.type) {
          case 'GT':
            if (e.target.value.length < wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
            }
            break
          case 'LT':
            if (e.target.value.length > wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
            }
            break
          case 'BETWEEN':
            if (
              e.target.value.length < wrapper.dataList[0].limitRange.start ||
              e.target.value.length > wrapper.dataList[0].limitRange.end
            ) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
            }
            break
        }
      }
    },
    inputNumberBlur(e, wrapper, item) {
      const ref = 'input' + wrapper.id
      if (e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
      } else if (!e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$nextTick(() => {
          this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
        })
      }
      if (e.target.value && !/(^[0-9]\d*$)/.test(e.target.value)) {
        this.$nextTick(() => {
          this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
          this.$refs[wrapper.id][0].style.display = 'flex'
        })
      } else if (e.target.value && /(^[0-9]\d*$)/.test(e.target.value)) {
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
        this.$refs[wrapper.id][0].style.display = 'none'
      }
      /* item.canSync = false */
    },
    getRange(data) {
      if (data.limitRange) {
        switch (data.limitRange.type) {
          case 'GT':
            return (
              data.limitRange.value &&
              data.formValues[0].value &&
              data.formValues[0].value.length + '/>' + data.limitRange.value + '字'
            )
            break
          case 'LT':
            return (
              data.limitRange.value &&
              data.formValues[0].value &&
              data.formValues[0].value.length + '/<' + data.limitRange.value + '字'
            )
            break
          case 'BETWEEN':
            return (
              data.limitRange.end &&
              data.formValues[0].value &&
              data.formValues[0].value.length + '/' + data.limitRange.start + '~' + data.limitRange.end + '字'
            )
            break
        }
      }
    },
    getClassName(wrapper, isInput) {
      setTimeout(() => {
        if (wrapper && isInput) {
          if (this.$refs['input' + wrapper.id][0].$el.style.height.split('p')[0] > 35) {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div'
            return 'description-div'
          } else {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div-one'
            return 'description-div-one'
          }
        } else if (wrapper && !isInput) {
          if (this.$refs['input' + wrapper.id][0].offsetHeight > 42) {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div'
            return 'description-div'
          } else {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div-one'
            return 'description-div-one'
          }
        }
      }, 0)
    },
    isSucDis(item) {
      let dis = true
      for (let p of this.userInfo.positions) {
        if (
          item.positions.some(i => {
            return i.positionId == p.id && i.type == 'EDIT'
          })
        ) {
          dis = false
          break
        }
      }
      if (this.isAdmin) dis = false
      return dis
    },
    inputChange(e, wrapper, item) {
      setTimeout(() => {
        if (wrapper.type == 'TEXTAREA') {
          if (e.target.style.height.split('p')[0] > 35) {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div'
          } else {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div-one'
          }
        }
      }, 0)
      if (e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[wrapper.id][0].style.display = 'none'
        e.target.style.borderColor = '#d9d9d9'
        e.target.classList.remove('antd-input-vis')
      } else if (!e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        /* this.$refs[wrapper.id][0].style.display = 'block' */
        e.target.style.borderColor = '#f5222d'
        e.target.classList.add('antd-input-vis')
      }
      if (wrapper.dataList[0].limitRange) {
        switch (wrapper.dataList[0].limitRange.type) {
          case 'GT':
            if (e.target.value.length < wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
              e.target.classList.add('antd-input-vis')
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
              e.target.classList.remove('antd-input-vis')
            }
            break
          case 'LT':
            if (e.target.value.length > wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
              e.target.classList.add('antd-input-vis')
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
              e.target.classList.remove('antd-input-vis')
            }
            break
          case 'BETWEEN':
            if (
              e.target.value.length < wrapper.dataList[0].limitRange.start ||
              e.target.value.length > wrapper.dataList[0].limitRange.end
            ) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
              e.target.classList.add('antd-input-vis')
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
              e.target.classList.remove('antd-input-vis')
            }
            break
        }
      }
      /* item.canSync = false */
    },
    kpiInputChange(e, kpi) {
      console.log(e)
      kpi.value = e
    }
  }
}
</script>

<style lang="less" scoped>
.left,
.right {
  position: absolute;
  width: 2vw;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: rgba(0, 0, 0, 0.1);
  }
  &:hover {
    i {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        ~ span {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }
}
.left {
  left: -40px;
}
.right {
  right: -40px;
}
.statusPointer {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 10px;
}
.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.no-required:before {
  display: inline-block;
  margin-right: 4px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  opacity: 0;
}
::-webkit-scrollbar-button {
  height: 4px;
}
::-webkit-scrollbar {
  width: 0px;
}
.ant-form {
  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }
  }
}
/* ::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
} */
.dis-tag {
  width: 18vw;
  min-height: 30px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 7px;
  border: 1px solid #e0e0e0;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  position: relative;
}
/deep/ .antd-input-vis {
  box-shadow: 0 0 0 0 !important;
}
/deep/ .ant-input-number-vis {
  border: 1px solid #f5222d !important;
  border-radius: 4px !important;
  box-shadow: 0 0 0 0 #f5222d !important;
}
/deep/ .ant-select-selection-vis {
  border-color: #f5222d !important;
  box-shadow: 0 0 0 0 #f5222d !important;
}
.zsxz {
  float: right;
  font-size: 12px;
  color: rgb(191, 191, 191);
  margin: auto 0;
  margin-left: 5px;
}
.tag-select {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid rgb(124, 170, 235);
  }
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
}
.tag-select-show {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  box-shadow: 0 0 0 1.5px rgb(124, 170, 235);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
  .ant-tag-checkable-checked {
    background-color: #5389df;
    color: #fff;
    cursor: pointer;
  }
}
/deep/ .ant-input-vis {
  box-shadow: 0 0 0 0 !important;
}
/deep/ .tag-select-open {
  position: absolute;
  border: 1px solid #d9d9d9;
  width: 503px;
  top: 36px;
  display: flex;
  left: -2px;
  height: max-content;
  padding-bottom: 3px;
  overflow-y: overlay;
  flex-wrap: wrap;
  background-color: #fff;
  padding-left: 7px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(235, 233, 233);
  transition: 0.2s;
  overflow: hidden;
  align-content: flex-start;
  z-index: 1000;
  .ant-tag {
    height: 25px;
    margin-top: 5px;
    line-height: 23px;
    &:hover {
      cursor: pointer;
    }
  }
}
.tag-select-close {
  position: absolute;
  display: none;
  transition: 0.2s;
  width: 503px;
  top: 30px;
  left: -2px;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px black;
}
/deep/ .ant-tag-checkable {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  border: 1px solid #91d5ff;
}
/deep/ .edit-form {
  .ant-form-item-label > label::after {
    display: none;
  }
}
/deep/ .show-form {
  overflow-y: overlay;
  max-height: 500px;
  .ant-form-item-label > label::after {
    display: none;
  }
}
.description-div-one {
  font-size: 12px;
  width: 600px;
  word-break: break-word;
  padding-left: 14px;
  color: #bfbfbf;
}
.description-div {
  font-size: 12px;
  margin-top: -11px;
  padding-left: 14px;
  line-height: 20px;
  width: 135px;
  white-space: pre-line;
  word-break: break-word;
  color: #bfbfbf;
}
/deep/ .ant-badge-status-processing {
  background-color: rgba(0, 0, 0, 0.65);
  margin-right: 3px;
  &::after {
    border: none;
  }
}
.select-span {
  position: absolute;
  font-size: 12px;
  right: 35px;
  color: rgb(191, 191, 191);
}
/deep/ .ant-form-item-children .form-select {
  margin-bottom: 2px;
  label {
    top: 6px;
  }
}
/deep/ .show-form {
  .ant-form-item {
    color: rgba(0, 0, 0, 0.65);
    width: 600px;
    margin-bottom: 12px;
  }
  .ant-form-item-children label {
    color: rgba(0, 0, 0, 0.65);
    top: 0;
  }
}
/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .only {
  margin-left: -101px !important;
}
/deep/ .ant-form-item {
  max-width: 100%;
  display: flex;
  margin-bottom: 12px;
  input,
  .ant-input-number {
    width: 18vw;
  }
}
.kpi-show {
  padding-right: 32%;
  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }
  }
}
/deep/ .coef_item .ant-form-item-control-input-content {
  display: flex !important;
}
/deep/ .ant-input-number-handler-wrap {
  display: none;
}
/deep/ .ant-input-number-input-wrap {
  input {
    padding-right: 5px;
  }
}
</style>
