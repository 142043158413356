var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { visible: _vm.visible, title: false, footer: false },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-title",
          staticStyle: { "padding-bottom": "5px" },
        },
        [
          _c("a-icon", {
            style: "margin-right:5px;color:" + _vm.color,
            attrs: { type: "info-circle" },
          }),
          _vm._v("以下作品的术语未确认，请确认后再更改任务状态！"),
        ],
        1
      ),
      _c(
        "div",
        { style: _vm.backCss },
        _vm._l(_vm.productionList, function (item) {
          return _c(
            "div",
            {
              key: item.sequenceNo,
              staticClass: "term-item",
              on: {
                click: function ($event) {
                  return _vm.goToTerm(item)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(item.sequenceNo) +
                  "-" +
                  _vm._s(item.productionName) +
                  "\n    "
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }