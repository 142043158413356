var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 700,
        zIndex: 2000,
        visible: _vm.visible,
        forceRender: "",
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _vm.taskList.length
            ? _c(
                "div",
                {
                  staticClass: "left",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _vm.taskPage
                    ? _c("a-icon", {
                        staticStyle: { "font-size": "30px" },
                        attrs: { type: "left-circle", theme: "filled" },
                        on: { click: _vm.prev },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.taskList.length
            ? _c(
                "div",
                {
                  staticClass: "right",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _vm.taskPage < _vm.taskList.length - 1
                    ? _c("a-icon", {
                        staticStyle: { "font-size": "30px" },
                        attrs: { type: "right-circle", theme: "filled" },
                        on: { click: _vm.next },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.radioValue,
                callback: function ($$v) {
                  _vm.radioValue = $$v
                },
                expression: "radioValue",
              },
            },
            [
              _vm.formData.details && _vm.formData.details.length
                ? _c("a-radio", { attrs: { value: "1" } }, [
                    _vm._v("\n        系数参数\n      "),
                  ])
                : _vm._e(),
              _vm.formData.noKpi
                ? _c("a-radio", { attrs: { value: "2" } }, [
                    _vm._v("\n        对其他阶段考核\n      "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.radioValue == "1"
            ? _c(
                "a-form-model",
                {
                  ref: "form",
                  staticStyle: {
                    "padding-top": "40px",
                    "overflow-y": "overlay",
                    "max-height": "300px",
                  },
                },
                _vm._l(_vm.formData.details, function (item) {
                  return _c(
                    "a-form-model-item",
                    _vm._b(
                      { key: item.id, staticClass: "edit-form" },
                      "a-form-model-item",
                      _vm.layout,
                      false
                    ),
                    [
                      item.cardList[0]
                        ? [
                            _c(
                              "div",
                              {
                                class:
                                  item.cardList[0].dataList[0].valueRequired ==
                                  "YES"
                                    ? "required"
                                    : "no-required",
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.title) +
                                    "\n            "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "-2px" } },
                                  [_vm._v(":")]
                                ),
                                item.cardList[0].dataList &&
                                item.cardList.length > 1
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          ref: item.cardList[0].id + "TEXTAREA",
                                          refInFor: true,
                                          staticClass: "description-div",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getSelectCount(
                                                  item.cardList[0]
                                                ) +
                                                  `${
                                                    item.description
                                                      ? item.description
                                                      : ""
                                                  }`
                                              ) +
                                              _vm._s(
                                                !item.canSync
                                                  ? "（已更新数据）"
                                                  : ""
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                item.cardList[0].dataList &&
                                item.cardList.length == 1
                                  ? _c("div", [
                                      item.cardList[0].type != "TEXTAREA"
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                item.cardList[0].dataList[0]
                                                  .formValues.length > 1 &&
                                                item.cardList[0].type != "TAG"
                                                  ? "description-div"
                                                  : "description-div-one",
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getSelectCount(
                                                      item.cardList[0]
                                                    ) +
                                                      `${
                                                        item.description
                                                          ? item.description
                                                          : ""
                                                      }`
                                                  ) +
                                                  _vm._s(
                                                    !item.canSync
                                                      ? "（已更新数据）"
                                                      : ""
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              ref:
                                                item.cardList[0].id +
                                                "TEXTAREA",
                                              refInFor: true,
                                              class: _vm.getClassName(
                                                item.cardList[0],
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getSelectCount(
                                                      item.cardList[0]
                                                    ) +
                                                      `${
                                                        item.description
                                                          ? item.description
                                                          : ""
                                                      }`
                                                  ) +
                                                  _vm._s(
                                                    !item.canSync
                                                      ? "（已更新数据）"
                                                      : ""
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._l(item.cardList, function (wrapper) {
                        return [
                          wrapper.dataList
                            ? _c(
                                "div",
                                {
                                  key: wrapper.id,
                                  style:
                                    wrapper.type == "TEXTAREA"
                                      ? "margin-bottom:2px;display:flex;flex-wrap:wrap"
                                      : "display:flex;flex-wrap:wrap",
                                },
                                [
                                  wrapper.type == "INPUT"
                                    ? _c("a-input", {
                                        ref: "input" + wrapper.id,
                                        refInFor: true,
                                        staticStyle: {
                                          "margin-bottom": "6px",
                                          "margin-top": "4px",
                                        },
                                        attrs: {
                                          placeholder:
                                            wrapper.dataList[0].description,
                                          disabled: _vm.isSucDis(item),
                                        },
                                        on: {
                                          blur: (e) => {
                                            _vm.inputBlur(e, wrapper)
                                          },
                                          change: (e) => {
                                            _vm.inputChange(e, wrapper)
                                          },
                                        },
                                        model: {
                                          value:
                                            wrapper.dataList[0].formValues[0]
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              wrapper.dataList[0].formValues[0],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "wrapper.dataList[0].formValues[0].value",
                                        },
                                      })
                                    : _vm._e(),
                                  wrapper.type == "INPUT_NUMBER"
                                    ? _c("a-input-number", {
                                        ref: "input" + wrapper.id,
                                        refInFor: true,
                                        staticStyle: {
                                          "margin-bottom": "6px",
                                          "margin-top": "4px",
                                        },
                                        attrs: {
                                          min: 0,
                                          placeholder:
                                            wrapper.dataList[0].description,
                                          disabled: _vm.isSucDis(item),
                                        },
                                        on: {
                                          blur: (e) => {
                                            _vm.inputNumberBlur(
                                              e,
                                              wrapper,
                                              item
                                            )
                                          },
                                          change: (e) => {
                                            _vm.inputNumberChange(
                                              e,
                                              wrapper,
                                              item
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            wrapper.dataList[0].formValues[0]
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              wrapper.dataList[0].formValues[0],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "wrapper.dataList[0].formValues[0].value",
                                        },
                                      })
                                    : _vm._e(),
                                  wrapper.type == "DYNAMIC_FORM"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "align-items": "center",
                                            width: "580px",
                                            "margin-top": "-6px",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            wrapper.dataList,
                                            function (data) {
                                              return [
                                                _c(
                                                  "a-form-model-item",
                                                  _vm._b(
                                                    {
                                                      key: data.id,
                                                      staticClass:
                                                        "form-select",
                                                      attrs: {
                                                        label: data.value,
                                                      },
                                                    },
                                                    "a-form-model-item",
                                                    _vm.inlineLayout,
                                                    false
                                                  ),
                                                  [
                                                    data.type != "INPUT"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "padding-top":
                                                                "6px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            data.formValues,
                                                            function (
                                                              formValue,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "a-select",
                                                                    {
                                                                      ref:
                                                                        "select" +
                                                                        data.id +
                                                                        index,
                                                                      refInFor: true,
                                                                      style:
                                                                        wrapper
                                                                          .dataList
                                                                          .length >
                                                                          1 &&
                                                                        !data.value
                                                                          ? "width:243px"
                                                                          : wrapper
                                                                              .dataList
                                                                              .length >
                                                                              1 &&
                                                                            data.value
                                                                          ? "width:173px"
                                                                          : data.value
                                                                          ? "width:430px;"
                                                                          : "width:18vw",
                                                                      attrs: {
                                                                        allowClear:
                                                                          "",
                                                                        placeholder:
                                                                          wrapper
                                                                            .dataList[0]
                                                                            .description,
                                                                        disabled:
                                                                          _vm.isSucDis(
                                                                            item
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          (
                                                                            e
                                                                          ) => {
                                                                            _vm.formSelectChange(
                                                                              e,
                                                                              data,
                                                                              wrapper.dataList,
                                                                              index,
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          formValue.value,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              formValue,
                                                                              "value",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "formValue.value",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      data.options,
                                                                      function (
                                                                        option
                                                                      ) {
                                                                        return _c(
                                                                          "a-select-option",
                                                                          {
                                                                            key: option.id,
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  option.value,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                option.label
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      ref:
                                                                        data.id +
                                                                        "" +
                                                                        index,
                                                                      refInFor: true,
                                                                      staticClass:
                                                                        "ant-form-explain",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          color:
                                                                            "#f5222d",
                                                                          display:
                                                                            "none",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        请选择" +
                                                                          _vm._s(
                                                                            item.title
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "6px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            data.formValues,
                                                            function (
                                                              formValue,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "a-input",
                                                                    {
                                                                      ref:
                                                                        "input" +
                                                                        data.id +
                                                                        index,
                                                                      refInFor: true,
                                                                      style:
                                                                        wrapper
                                                                          .dataList
                                                                          .length >
                                                                          1 &&
                                                                        !data.value
                                                                          ? "width:243px"
                                                                          : wrapper
                                                                              .dataList
                                                                              .length >
                                                                              1 &&
                                                                            data.value
                                                                          ? "width:173px"
                                                                          : data.value
                                                                          ? "width:430px;"
                                                                          : "width:18vw",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.isSucDis(
                                                                            item
                                                                          ),
                                                                        placeholder:
                                                                          wrapper
                                                                            .dataList[0]
                                                                            .type ==
                                                                          "INPUT"
                                                                            ? wrapper
                                                                                .dataList[0]
                                                                                .description
                                                                            : "",
                                                                      },
                                                                      on: {
                                                                        blur: (
                                                                          e
                                                                        ) => {
                                                                          _vm.formInputBlur(
                                                                            e,
                                                                            data,
                                                                            wrapper.dataList,
                                                                            index
                                                                          )
                                                                        },
                                                                        change:
                                                                          (
                                                                            e
                                                                          ) => {
                                                                            _vm.formInputChange(
                                                                              e,
                                                                              data,
                                                                              wrapper.dataList,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          formValue.value,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              formValue,
                                                                              "value",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "formValue.value",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      ref:
                                                                        data.id +
                                                                        "" +
                                                                        index,
                                                                      refInFor: true,
                                                                      staticClass:
                                                                        "ant-form-explain",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          color:
                                                                            "#f5222d",
                                                                          display:
                                                                            "none",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        请输入" +
                                                                          _vm._s(
                                                                            item.title
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                  ]
                                                ),
                                              ]
                                            }
                                          ),
                                          wrapper.canAdd
                                            ? _c(
                                                "div",
                                                {
                                                  class:
                                                    wrapper.dataList.length > 1
                                                      ? ""
                                                      : "only",
                                                  staticStyle: {
                                                    "min-width": "45px",
                                                    "margin-left": "10px",
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    height: "100%",
                                                    "padding-top": "16px",
                                                  },
                                                },
                                                [
                                                  _c("a-button", {
                                                    staticClass: "item-btn",
                                                    attrs: {
                                                      disabled:
                                                        _vm.isSucDis(item),
                                                      shape: "circle",
                                                      size: "small",
                                                      icon: "minus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          wrapper
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("a-button", {
                                                    staticClass: "item-btn",
                                                    staticStyle: {
                                                      "margin-left": "8px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.isSucDis(item),
                                                      shape: "circle",
                                                      size: "small",
                                                      icon: "plus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItem(
                                                          wrapper
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !wrapper.canAdd
                                            ? _c("div", {
                                                staticStyle: {
                                                  width: "55px",
                                                  "margin-left": "10px",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  wrapper.type == "SELECT"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-select",
                                          staticStyle: { width: "18vw" },
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              ref: "select" + wrapper.id,
                                              refInFor: true,
                                              attrs: {
                                                disabled: _vm.isSucDis(item),
                                                allowClear: "",
                                                showArrow: true,
                                                mode:
                                                  wrapper.dataList[0]
                                                    .selectCount == 1
                                                    ? ""
                                                    : "multiple",
                                                placeholder:
                                                  wrapper.dataList[0]
                                                    .description,
                                              },
                                              on: {
                                                change: (e) => {
                                                  _vm.selectChange(e, wrapper)
                                                },
                                              },
                                              model: {
                                                value:
                                                  wrapper.dataList[0]
                                                    .selectValues,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    wrapper.dataList[0],
                                                    "selectValues",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "wrapper.dataList[0].selectValues",
                                              },
                                            },
                                            _vm._l(
                                              wrapper.dataList[0].options,
                                              function (option) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: option.id,
                                                    attrs: {
                                                      value: option.value,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "option",
                                                        },
                                                        slot: "option",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              option.label
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  wrapper.type == "TAG" && !_vm.isSucDis(item)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-select",
                                          staticStyle: { width: "18vw" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "40px",
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class: wrapper.isTagShow
                                                    ? "tag-select-show"
                                                    : "tag-select",
                                                  on: {
                                                    click: function ($event) {
                                                      if (
                                                        $event.target !==
                                                        $event.currentTarget
                                                      )
                                                        return null
                                                      return _vm.isTagShow(
                                                        wrapper
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  wrapper.dataList[0]
                                                    .formValues &&
                                                  wrapper.dataList[0]
                                                    .formValues[0].value
                                                    ? _vm._l(
                                                        wrapper.dataList[0]
                                                          .formValues,
                                                        function (formValue) {
                                                          return _c(
                                                            "a-tag",
                                                            {
                                                              key: formValue.value,
                                                              attrs: {
                                                                closable: "",
                                                              },
                                                              on: {
                                                                close:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.tagClose(
                                                                      formValue.value,
                                                                      wrapper
                                                                        .dataList[0]
                                                                        .formValues,
                                                                      wrapper
                                                                        .dataList[0]
                                                                        .options
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  formValue.value
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tag-select-open",
                                                      style: wrapper.isTagShow
                                                        ? ""
                                                        : "height:0px;border:none",
                                                    },
                                                    [
                                                      wrapper.dataList[0]
                                                        .formValues[0].value
                                                        ? _vm._l(
                                                            wrapper.dataList[0]
                                                              .options,
                                                            function (option) {
                                                              return _c(
                                                                "a-checkable-tag",
                                                                {
                                                                  key: option.label,
                                                                  ref: option.label,
                                                                  refInFor: true,
                                                                  style:
                                                                    wrapper
                                                                      .dataList[0]
                                                                      .formValues
                                                                      .length >=
                                                                      wrapper
                                                                        .dataList[0]
                                                                        .selectCount &&
                                                                    !option.checked
                                                                      ? "pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;"
                                                                      : "",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.tagChange(
                                                                          option.checked,
                                                                          wrapper,
                                                                          option
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      option.checked,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          option,
                                                                          "checked",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "option.checked",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option.label
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        : _vm._l(
                                                            wrapper.dataList[0]
                                                              .options,
                                                            function (option) {
                                                              return _c(
                                                                "a-checkable-tag",
                                                                {
                                                                  key: option.label,
                                                                  ref: option.label,
                                                                  refInFor: true,
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.tagChange(
                                                                          option.checked,
                                                                          wrapper,
                                                                          option
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      option.checked,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          option,
                                                                          "checked",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "option.checked",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option.label
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ant-select-arrow",
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        staticStyle: {
                                                          color:
                                                            "rgba(0, 0, 0, 0.25)",
                                                        },
                                                        attrs: {
                                                          type: wrapper.isTagShow
                                                            ? "up"
                                                            : "down",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : wrapper.type == "TAG" &&
                                      _vm.isSucDis(item)
                                    ? _c("div", { staticClass: "dis-tag" }, [
                                        _c(
                                          "span",
                                          { staticClass: "ant-select-arrow" },
                                          [
                                            _c("a-icon", {
                                              staticStyle: {
                                                color: "rgba(0, 0, 0, 0.25)",
                                              },
                                              attrs: { type: "down" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  wrapper.type == "TEXTAREA"
                                    ? _c("a-textarea", {
                                        ref: "input" + wrapper.id,
                                        refInFor: true,
                                        staticStyle: {
                                          width: "18vw",
                                          "margin-top": "4px",
                                        },
                                        attrs: {
                                          disabled: _vm.isSucDis(item),
                                          "auto-size": {
                                            minRows: 1,
                                            maxRows: 5,
                                          },
                                          placeholder:
                                            wrapper.dataList[0].description,
                                        },
                                        on: {
                                          blur: (e) => {
                                            _vm.inputBlur(e, wrapper)
                                          },
                                          change: (e) => {
                                            _vm.inputChange(e, wrapper, item)
                                          },
                                        },
                                        model: {
                                          value:
                                            wrapper.dataList[0].formValues[0]
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              wrapper.dataList[0].formValues[0],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "wrapper.dataList[0].formValues[0].value",
                                        },
                                      })
                                    : _vm._e(),
                                  wrapper.type == "INPUT" ||
                                  wrapper.type == "TEXTAREA"
                                    ? _c("div", { staticClass: "zsxz" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getRange(wrapper.dataList[0])
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  wrapper.type == "INPUT" ||
                                  wrapper.type == "TEXTAREA" ||
                                  wrapper.type == "INPUT_NUMBER"
                                    ? _c(
                                        "div",
                                        {
                                          ref: wrapper.id,
                                          refInFor: true,
                                          staticClass: "ant-form-explain",
                                          staticStyle: {
                                            color: "#f5222d",
                                            display: "none",
                                            "margin-left": "5px",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              请输入整数数字\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  wrapper.type == "SELECT"
                                    ? _c(
                                        "div",
                                        {
                                          ref: wrapper.id,
                                          refInFor: true,
                                          staticClass: "ant-form-explain",
                                          staticStyle: {
                                            width: "100%",
                                            color: "#f5222d",
                                            display: "none",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              请选择" +
                                              _vm._s(item.title) +
                                              "\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.formData.noKpi && _vm.radioValue == "2"
            ? _c(
                "div",
                { staticStyle: { width: "80%", "padding-top": "20px" } },
                _vm._l(_vm.kpiList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticStyle: { "padding-bottom": "40px" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "coef_item",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "14px",
                              },
                            },
                            [
                              _vm._v(
                                "当前阶段对【" +
                                  _vm._s(item.stage.stageName) +
                                  "】阶段考核结果"
                              ),
                            ]
                          ),
                          _c("div"),
                        ]
                      ),
                      _vm._l(
                        item.kpiValues.filter((kpi) => {
                          return (
                            (_vm.formData.stageName == "监制" &&
                              (kpi.errorType == "SUPER_HARD" ||
                                kpi.errorType == "HARD" ||
                                kpi.errorType == "SUPER_SOFT" ||
                                kpi.errorType == "SOFT")) ||
                            (_vm.formData.stageName == "监制确认" &&
                              (kpi.errorType == "V1_HARD" ||
                                kpi.errorType == "V2_HARD" ||
                                kpi.errorType == "MORE_THAN_V2_HARD")) ||
                            (_vm.formData.stageName != "监制确认" &&
                              _vm.formData.stageName != "监制")
                          )
                        }),
                        function (kpi) {
                          return [
                            kpi.kpiType == "KPI_ERRORS"
                              ? _c(
                                  "div",
                                  {
                                    key: kpi.id,
                                    staticClass: "coef_item",
                                    staticStyle: {
                                      display: "flex",
                                      "padding-top": "13px",
                                      "justify-content": "space-between",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  kpi.errorType == "SOFT"
                                                    ? "软伤数"
                                                    : kpi.errorType == "HARD"
                                                    ? "硬伤数"
                                                    : kpi.errorType == "ERROR"
                                                    ? "错翻数"
                                                    : kpi.errorType ==
                                                      "SUPER_HARD"
                                                    ? "超级硬伤数"
                                                    : kpi.errorType == "V1_HARD"
                                                    ? "1改硬伤数"
                                                    : kpi.errorType == "V2_HARD"
                                                    ? "2改硬伤数"
                                                    : kpi.errorType ==
                                                      "MORE_THAN_V2_HARD"
                                                    ? "2改以上硬伤数"
                                                    : kpi.errorType ==
                                                      "SUPER_SOFT"
                                                    ? "超级软伤"
                                                    : ""
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "14px" } },
                                      [
                                        kpi.isEdit
                                          ? _c(
                                              "div",
                                              [
                                                _c("a-input-number", {
                                                  staticStyle: {
                                                    width: "45px",
                                                    "padding-left": "4px",
                                                    "padding-right": "4px",
                                                    "font-size": "14px",
                                                    "padding-top": "0",
                                                    "padding-bottom": "0",
                                                  },
                                                  attrs: { min: 0 },
                                                  on: {
                                                    change: (e) => {
                                                      _vm.kpiInputChange(e, kpi)
                                                    },
                                                  },
                                                  model: {
                                                    value: kpi.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        kpi,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "kpi.value",
                                                  },
                                                }),
                                                _vm._v("个\n              "),
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  kpi.value == null
                                                    ? "/   "
                                                    : kpi.value
                                                ) + "个"
                                              ),
                                            ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }
                      ),
                      _vm._l(item.kpiValues, function (kpi) {
                        return [
                          kpi.kpiType != "KPI_ERRORS" &&
                          _vm.formData.stageName != "监制确认"
                            ? _c(
                                "div",
                                {
                                  key: kpi.id,
                                  staticClass: "coef_item",
                                  staticStyle: {
                                    display: "flex",
                                    "padding-top": "13px",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                修改量\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      kpi.isEdit
                                        ? _c(
                                            "div",
                                            [
                                              _c("a-input-number", {
                                                staticStyle: {
                                                  width: "45px",
                                                  "padding-left": "4px",
                                                  "padding-right": "4px",
                                                  "font-size": "14px",
                                                  "padding-top": "0",
                                                  "padding-bottom": "0",
                                                },
                                                attrs: { min: 0 },
                                                on: {
                                                  change: (e) => {
                                                    _vm.kpiInputChange(e, kpi)
                                                  },
                                                },
                                                model: {
                                                  value: kpi.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(kpi, "value", $$v)
                                                  },
                                                  expression: "kpi.value",
                                                },
                                              }),
                                              _vm._v("个\n              "),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                kpi.value == null
                                                  ? "/   "
                                                  : kpi.value
                                              ) + "个"
                                            ),
                                          ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
              _vm._v("\n        关闭\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { loading: _vm.btnLoading },
                on: { click: _vm.handleOk },
              },
              [
                !_vm.btnLoading
                  ? _c("div", {
                      staticClass: "statusPointer",
                      style: { background: "green" },
                    })
                  : _vm._e(),
                _c("span", [_vm._v("已完成")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("term-list-modal", { ref: "termListModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }